<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 拼团详情
 * @Date: 2021-01-21 15:42:18
 * @LastEditors: 旭日
 * @LastEditTime: 2021-08-03 21:25:13
 * @FilePath: \yue_quanzhan_h5\src\views\user\order\Spelldetail.vue
-->
<template>
  <div class="user_content">
    <div class="details_top">
      <div>{{ list.orderStatus | Status }}</div>
      <div v-if="list.assembleState.length" class="Step">
        <div class="step_items">
          <div v-for="(item,index) in list.assembleState" :key="index" class="step_item">
            <div v-if="index !== list.assembleState.length - 1" class="step_line" />
            <div class="step_icon">
              <i v-if="item.showType === 0" class="ces" />
              <!-- <img src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-01-21/18/yuelvhuiHwh6Ca2i2c1611225946.png" alt=""> -->
              <img
                v-else
                src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-01-21/18/yuelvhui1XVOqpSwK71611225260.png"
                alt=""
              >
            </div>
            <div class="step_title">{{ item.stateName }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="details_name">
      <div class="title">收获地址</div>
      <div class="detail_name"><span>收货人：{{ list.linkName }}</span><span>{{ list.linkTel }}</span></div>
      <div>{{ list.address }}</div>
    </div>
    <div v-if="list.memberInfo.length" class="spell">
      <div class="title">拼团成功</div>
      <div class="spell_box">
        <ul class="spell_ul">
          <li v-for="(item,index) in list.memberInfo" :key="index">
            <img :src="item.head_pic" alt="">
            <span v-if="item.isTeam === 1">团长</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="commodity">
      <div class="title">订单信息</div>
      <div v-for="(item,index) in list.goodsInfo" :key="index">
        <div class="comm" @click="details(item)">
          <div class="comm_img"><img :src="item.goodsImg" alt=""></div>
          <div class="comm_test">
            <div>{{ item.goodsName }}</div>
            <div>
              <span>¥{{ list.totalMoney }}</span>
              <span>*{{ item.goodsNum }}</span>
            </div>
          </div>
        </div>
        <div class="comm_btn">
          <b v-if="item.refundButtonType === 3">{{ item.refundButtonText }}</b>
          <span v-if="item.isMakesure === 1" @click="confirm(item)">确认收货</span>
          <span v-if="item.isCanComment === 1" @click="evaluate(item)">去评价</span>
          <span v-if="item.isCan === 1" @click="logistics(item)">查看物流</span>
        </div>
      </div>
      <div class="comm_z">
        <div><span>商品总计</span><span>¥{{ list.totalMoney }}</span></div>
        <div><span>优惠劵</span><span>¥{{ list.couponPrice }}</span></div>
        <div><span>总运费</span><span>¥{{ list.totalFeight }}</span></div>
        <div><span style="color:#000;">实际付款</span><span style="color:#F7263c;">¥{{ list.actualPrice }}</span></div>
      </div>
    </div>
    <div class="details_orderNo">
      <div class="orderNo">
        <span>订单编号: <i id="ordernumber">{{ list.orderNo }}</i> </span><span class="copy" @click="copy(list.orderNo)">复制</span>
      </div>
      <div>下单时间: {{ list.createTime }}</div>
      <div>支付方式: {{ list.payTypeDes }}</div>
    </div>

    <van-dialog
      v-model="show"
      message="如已经支付，请不要重新支付！"
      confirm-button-text="继续支付"
      show-cancel-button
      @confirm="pay(list)"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { Dialog, Toast } from 'vant'

Vue.use(Toast).use(Dialog)
import {
  hdorderDetail,
  mallNewOrderDelete,
  confirmReceipt
  // getPayVersion,
  // newMallPayV4
} from '@/services/userApi'
import { weChatPay } from '@/utils/payment'
import { copyText } from '@/utils/common/utils'

export default {
  filters: {
    Status(v) {
      const map = {
        0: '待支付',
        1: '待发货',
        2: '已发货',
        3: '已取消',
        4: '已完成'
      }
      return map[v]
    }
  },
  data() {
    return {
      show: false,
      orderNo: '',
      uid: '',
      list: '',
      secunds: '',
      strTimer: '',
      openId: ''
    }
  },
  created() {
    this.uid = localStorage.getItem('uid')
    this.openId = localStorage.getItem('openid')
    this.orderNo = this.$route.query.orderNo
    this.getinfo()
  },
  methods: {
    // 支付倒计时
    computetTime(totalSecond) {
      const that = this
      let sec = parseInt(totalSecond)
      let clockCount = {}
      let strTimer = ''
      clockCount = setInterval(() => {
        if (sec === 0) {
          clearInterval(clockCount)
          return false
        }
        strTimer = that.secundTodata(sec)
        that.strTimer = strTimer
        sec--
      }, 1000)
    },
    secundTodata(result) {
      var h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600)
      var m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60))
      var s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60))
      if (h === 0) {
        result = m + ':' + s
      } else {
        result = h + ':' + m + ':' + s
      }
      return result
    },
    getinfo() {
      const prom = {
        ordersn: this.orderNo,
        fromType: 2
      }
      hdorderDetail(prom).then(res => {
        if (Number(res.code) === 200) {
          this.list = res.data
          this.secunds = res.data.countDowm
          this.computetTime(res.data.countDowm)
        }
      })
    },
    copy(orderNo) {
      copyText(orderNo, () => {
        Toast('复制成功')
      })
    },
    details(o) {
      const obj = {
        goodId: o.goodsId,
        skuId: o.skuId,
        product_type: 1
      }
      this.$store.dispatch('godetail', obj)
    },
    // 查看物流
    logistics(v) {
      this.$router.push({
        path: '/logistics',
        query: {
          recordId: v.recordId,
          goodName: v.goodsName,
          goodSpec: v.goodsSpec,
          payPrice: v.goodsPrice,
          goodCover: v.goodsImg,
          goodNum: v.goodsNum
        }
      })
    },
    // 删除订单
    Delete(n) {
      Dialog.confirm({
        message: '确认取消此订单？'
      }).then(() => {
        const prom = {
          orderNo: n.orderNo
        }
        mallNewOrderDelete(prom).then(res => {
          if (Number(res.code) === 200) {
            Toast(res.msg)
            window.history.go(-1)
          } else {
            Toast(res.msg)
          }
        })
      })
    },
    // 支付
    payment() {
      this.show = true
    },
    // 发起支付
    pay(n) {
      this.show = false
      // const _self = this

      this.$router.push({
        path: '/pay',
        query: {
          orderNo: n.orderNo,
          goodsId: n.goodsId
        }
      })
      // return

      // getPayVersion({ type: 1 }).then(res => {
      //   if (Number(res.code) === 200) {
      //     const version = res.data && res.data.status
      //     const patmr = {
      //       ordersn: n.orderNo,
      //       openId: _self.openId,
      //       type: 147,
      //       payVersion: version,
      //       returnUrl: 'https://web.yuetao.vip/odersky?odersn=' + this.orderNo
      //     }
      //     newMallPayV4(patmr).then(res => {
      //       if (Number(res.code) === 200) {
      //         _self.ordersn = res.ordersn
      //         const params = res.pay.getwayBody
      //         _self.wxPay(params)
      //       } else {
      //         Toast(res.msg)
      //       }
      //     })
      //   }
      // })
    },
    /**
     * 微信支付
     * @param params
     */
    wxPay(params) {
      const vm = this
      weChatPay(params).then(res => {
        Toast('支付成功')
        vm.isDisabledSubmitBtn = true
        vm.$router.push({
          path: '/oderSky',
          query: { ordersn: vm.ordersn }
        })
      }).catch(e => {
        if (e) {
          const { msg = null } = e
          if (msg === 'isNotWeChat') {
            Toast('请在微信浏览器下进行支付')
          }
          if (msg === 'cancel') {
            // 取消支付的操作
            Toast('取消支付')
            vm.$router.push({
              path: '/oderSky',
              query: { ordersn: vm.ordersn }
            })
          }
          if (msg === 'fail') {
            // 支付失败的操作
            Toast('支付失败')
            vm.$router.push({
              path: '/oderSky',
              query: { ordersn: vm.ordersn }
            })
          }
        }
        vm.isDisabledSubmitBtn = false // 按钮恢复高亮
      })
    },
    // onBridgeReady(params) {
    //   var that = this
    //   var timestamp = Math.round(params.timeStamp).toString()
    //
    //   // eslint-disable-next-line no-undef
    //   WeixinJSBridge.invoke(
    //     'getBrandWCPayRequest',
    //     {
    //       appId: params.appId, // 公众号名称，由商户传入
    //       timeStamp: timestamp, // 时间戳，自1970年以来的秒数
    //       nonceStr: params.nonceStr, // 随机串
    //       package: params.package,
    //       signType: params.signType, // 微信签名方式：
    //       paySign: params.paySign, // 微信签名
    //       jsApiList: ['chooseWXPay']
    //     },
    //     function(res) {
    //       // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
    //       if (res.err_msg === 'get_brand_wcpay_request:ok') {
    //         // 支付成功后的操作
    //         Toast('支付成功')
    //         that.isDisabledSubmitBtn = true
    //         that.$router.push({
    //           path: '/oderSky',
    //           query: { ordersn: that.ordersn }
    //         })
    //       } else if (
    //         res.err_msg === 'get_brand_wcpay_request:cancel'
    //       ) {
    //         // 取消支付的操作
    //         Toast('取消支付')
    //         that.isDisabledSubmitBtn = false // 按钮恢复高亮
    //         that.$router.push({
    //           path: '/oderSky',
    //           query: { ordersn: that.ordersn }
    //         })
    //       } else {
    //         // 支付失败的操作
    //         Toast('支付失败')
    //         that.isDisabledSubmitBtn = false // 按钮恢复高亮
    //         that.$router.push({
    //           path: '/oderSky',
    //           query: { ordersn: that.ordersn }
    //         })
    //       }
    //     }
    //   )
    // },
    // 去评价
    evaluate(n) {
      this.$router.push({
        path: '/evaluate',
        query: {
          info: n
        }
      })
    },
    confirm(n) {
      const prom = {
        subOrderSn: n.subOrderSn
      }
      confirmReceipt(prom).then(res => {
        if (Number(res.code) === 200) {
          Toast(res.msg)
        } else {
          Toast(res.msg)
        }
      })
    }

  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .spell {
    background: #fff;
    padding: 13px;
    margin-bottom: 10px;
    overflow: hidden;

    .spell_box {
      overflow-x: auto;
      overflow-y: hidden;
      height: 100px;
      position: relative;

      .spell_ul {
        position: absolute;
        left: 0px;
        top: 0px;
        overflow-x: auto;
        white-space: nowrap;
        display: flex;

        & > li {
          display: inline-block;
          width: 52px;
          height: 52px;
          margin: 15px 20px;
          position: relative;

          & > img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }

          & > span {
            padding: 4px 10px;
            border-radius: 7px;
            background: #E33F44;
            color: #fff;
            position: absolute;
            bottom: -15px;
            left: 0;
          }
        }
      }
    }
  }

  .title {
    text-align: left;
    font-weight: 500;
  }

  .orderfoot {
    position: fixed;
    left: 0;
    bottom: 0;
    background: #fff;
    padding: 13px;
    border-top: 1px solid #eee;
    width: 100%;
    text-align: right;

    & > span {
      margin-left: 10px;
      padding: 5px;
      border: 1px solid #999;
      border-radius: 10px;
      font-size: 10px;
    }

    .zf {
      border: 1px solid #F7263C;
      color: #fff;
      background: #F7263C;
    }
  }

  .details_top {
    padding: 20px;
    background: #F7263C;
    color: #fff;
    text-align: left;
    font-weight: 500;
    line-height: 25px;

    .Step {
      padding: 20px 10px;

      .step_items {
        display: flex;
        margin: 0 0 10px;
        padding-bottom: 10px;

        .step_item {
          position: relative;
          color: #FFFFFF;
          flex: 1;
          font-size: 14px;

          .step_title {
            position: absolute;
            top: 10px;
            left: 25px;
            font-size: 12px;
            margin-left: 0;
            display: inline-block;
            transform: translateX(-50%);
          }

          .step_icon {
            position: absolute;
            // top: 30px;
            left: 20px;
            z-index: 1;
            transform: translateY(-50%);

            .ces {
              display: block;
              width: 19px;
              height: 19px;
              border-radius: 50%;
              background: #ffff;
            }

            & > img {
              display: block;
              width: 19px;
              height: 19px;
              background-color: #969799;
              border-radius: 50%;
            }
          }

          .step_line {
            position: absolute;
            top: -3px;
            left: 50px;
            width: 50%;
            height: 4px;
            border-radius: 5px;
            background-color: #ebedf0;
          }
        }
      }
    }
  }

  .details_name {
    background: #FFFFFF;
    padding: 13px;
    margin-bottom: 10px;
    line-height: 25px;

    .detail_name {
      display: flex;
      justify-content: space-between;
    }

    & > div {
      text-align: left;
    }

    & > div:nth-child(1) {
      font-weight: 500;

      & > span:nth-child(1) {
        margin-right: 40px;
      }
    }

    & > div:nth-child(2) {
      margin-top: 10px;
      font-size: 12px;
    }
  }

  .commodity {
    background: #fff;
    padding: 13px;

    .comm {
      display: flex;
      margin-top: 10px;

      .comm_img {
        margin-right: 10px;

        img {
          border-radius: 10px;
          max-width: 100px;
          max-height: 100px;
        }
      }

      .comm_test {
        text-align: left;
        font-size: 12px;

        & > div:nth-child(1) {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          line-height: 20px;
          font-size: 14px;
        }

        & > div:nth-child(2) {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          font-size: 12px;

          & > span:nth-child(1) {
            font-weight: 500;
          }

          & > span:nth-child(2) {
            color: #999;
          }
        }

        & > div:nth-child(3) {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;

          & > span:nth-child(1) {
            font-weight: 500;
          }
        }
      }
    }

    .comm_btn {
      padding: 13px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & > span {
        margin-left: 10px;
        padding: 5px;
        border: 1px solid #999;
        border-radius: 10px;
        font-size: 10px;
      }

      & > b {
        color: rgb(248, 142, 3);
        font-weight: 400;
        font-size: 12px;
      }
    }

    .comm_z {
      margin-top: 10px;

      & > div {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        color: #999;
        font-size: 12px;
      }
    }
  }

  .details_orderNo {
    background: #fff;
    padding: 13px;
    margin-top: 10px;
    text-align: left;
    line-height: 20px;
    color: #999;
    font-size: 12px;

    .orderNo {
      display: flex;
      justify-content: space-between;

      .copy {
        margin-left: 10px;
        border: 1px solid #999;
        padding: 2px 10px;
        border-radius: 4px;
      }
    }
  }
}
</style>
